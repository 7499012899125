import React from 'react'
import Contact from '../components/Contact'

const ContactUs = () => {
  return (
    <div>
      <Contact/>
    </div>
  )
}

export default ContactUs;

import React from "react";
import { Link } from "react-router-dom";

const index = () => {
  return (
    <div className="max-w-[1100px] mx-auto">
      <div className="text-center flex flex-col ">
        <h1 className="font-bold text-[30px] my-2">Our Areas of Practice</h1>
        <p className="w-[90%] md:w-[70%] lg:w-[50%] mx-auto  py-2">
          GST Registration Process in India is completely online. It requires no
          manual intervention or no physical paper submissions. A very simple
          procedure has been prescribed for GST Registration
        </p>
      </div>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
        <div className=" shadow-lg flex flex-col justify-end">
          <img
            src='/assets/images/Marriage-scaled.jpg'
            alt="card"
            className="p-7"
          />
          <h1 className="text-center font-bold text-[18px]  ">
            Marriage Registration
          </h1>
          <p className="text-center py-2 px-5">
            Court Marriage Court marriage, also known as ci
          </p>
          <Link
            to="/services/marriage-registration"
            className="text-center mb-10 text-blue-700 underline"
          >
            Read More
          </Link>
        </div>
        <div className=" shadow-lg flex flex-col justify-end">
          <img
            src="/assets/images/MSME-scaled.jpg"
            alt="card"
            className="p-7"
          />
          <h1 className="text-center font-bold text-[18px]  ">MSME</h1>
          <p className="text-center py-2 px-5">
            Proprietorship Partnership One Person Company L
          </p>
          <a
            to="/services/msme"
            className="text-center mb-10 text-blue-700 underline"
          >
            Read More
          </a>
        </div>
        <div className=" shadow-lg flex flex-col justify-end">
          <img src="/assets/images/accounting.png" alt="card" className="p-7" />
          <h1 className="text-center font-bold text-[18px]  ">
            Accounting Services
          </h1>
          <p className="text-center py-2 px-5">
            ITR Return Filing IT return filing refers to the proc
          </p>
          <a href="" className="text-center mb-10 text-blue-700 underline">
            Read More
          </a>
        </div>
        <div className=" shadow-lg flex flex-col justify-end">
          <img src="/assets/images/Trademark.png" alt="card" className="p-7" />
          <h1 className="text-center font-bold text-[18px]  ">
            Trademark Services
          </h1>
          <p className="text-center py-2 px-5">
            Trademark Registration Trademark registration refers
          </p>
          <a href="" className="text-center mb-10 text-blue-700 underline">
            Read More
          </a>
        </div>
        <div className=" shadow-lg flex flex-col justify-end">
          <img
            src="/assets/images/GSTconsultants.jpeg"
            alt="card"
            className="p-7"
          />
          <h1 className="text-center font-bold text-[18px]  ">
            GST Consultant
          </h1>
          <p className="text-center py-2 px-5">
            GST Audit GST audit refers to the examination and ver
          </p>
          <a href="" className="text-center mb-10 text-blue-700 underline">
            Read More
          </a>
        </div>
      </div>
      <div className="flex items-center justify-between ">
        <button className="px-6 py-2 rounded bg-[#BB2D3B] text-white mx-auto  my-8">
          All Service
        </button>
      </div>
    </div>
  );
};

export default index;

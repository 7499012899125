import React from 'react'
import Services from '../components/Services'

const OurServices = () => {
  return (
    <div>
      <Services/>
    </div>
  )
}

export default OurServices

import React from 'react'
import TopHeader from './TopHeader'
import BottomHeader from './BottomHeader'

const Header = () => {
  return (
    <div>
        <TopHeader/>
        <BottomHeader/>
    </div>
  )
}

export default Header
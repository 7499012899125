import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./Home";
import AboutUs from "./AboutUs";
import ContactUs from "./ContactUs";
import OurServices from "./OurServices";
import MarriageRegisteration from "./MarriageRegisteration";
import MSME from "./MSME";
import Header from "../components/Header";
import Footer from "../components/Footer";

const Routing = () => {
  return (
    <div>
      <Header />
      <Routes>
        <Route exact path='/' element={<Home />} />
        <Route exact path='/about-us' element={<AboutUs />} />
        <Route exact path='/contact-us' element={<ContactUs />} />
        <Route exact path='/our-service' element={<OurServices />} />
        <Route
          exact
          path='/services/marriage-registeration'
          element={<MarriageRegisteration />}
        />
        <Route exact path='/services/msme' element={<MSME />} />
      </Routes>
      <Footer />
    </div>
  );
};

export default Routing;

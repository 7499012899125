import React from 'react'
import Header from '../components/Header'
import Carousal from '../components/Carousal'
import ContactUs from '../components/Contact'
import Count from '../components/Count'
import GSTConsultants from '../components/GSTConsultants'
import Practice from '../components/Practice'
import Testimonial from '../components/Testimonial'
import Footer from '../components/Footer'


const Home = () => {
  return (
    <div>
      <Carousal />
      <GSTConsultants />
      <Practice />
      <Testimonial />
      <ContactUs />
      <Count />
    </div>
  );
}

export default Home

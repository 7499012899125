import React from "react";

const MSME = () => {
  return (
    <section>
      <div className=" bg-black">
        <h1 className="px-[10%] text-[30px] font-bold text-white py-[7%]">
          MSME
        </h1>
      </div>
      <div className="max-w-[1100px] mx-auto my-10">
        <div>
          <ul className="list-disc px-20">
            <li>Proprietorship</li>
            <li>Partnership</li>
            <li>One Person Company</li>
            <li>Limited Liability Partnership</li>
            <li>Private Limited Company</li>
            <li>Startup India Registration</li>
            <li>Indian Subsidiary</li>
            <li>Nidhi Company</li>
            <li>Digital Signature</li>
            <li>Udyam Registration</li>
            <li>FSSAI Registration</li>
            <li>Check Company or LLP Name Availability</li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default MSME;

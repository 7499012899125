import React from 'react'

const MarriageRegisteration = () => {
  return (
    <section>
    <div className=" bg-black">
      <h1 className="px-[10%] text-[30px] font-bold text-white py-[7%]">
        Marriage Registration
      </h1>
    </div>
    <div className="max-w-[1100px] mx-auto my-10">
      <h1 className="font-bold text-[22px]">Court Marriage</h1>
      <p className="py-2">
        Court marriage, also known as civil marriage or registry marriage, is
        a legal process of getting married that takes place in a courthouse or
        a registry office. It is a simple and straightforward process that
        allows couples to establish a legal recognition of their relationship
        without any religious or traditional ceremonies. Here is a general
        outline of the process involved in a court marriage: Marriage
        Registration Application: The couple needs to fill out an application
        form for marriage registration at the local marriage registrar’s
        office. The application usually requires details such as the couple’s
        names, addresses, ages, occupations, and a declaration of their
        intention to marry.
      </p>
      <h3 className="font-bold py-4">Marriage certificate</h3>
      <div>
        <h3 className="font-bold text-center py-4">
          Documents Required for Registering Court Marriage
        </h3>
        <ul className="list-disc px-20">
          <li>
            Aadhar card, Pan Card, and two passport-size pictures for the
            bride and groom
          </li>
          <li>
            Aadhar Card and two passport-size pictures of the witnesses, the
            witnesses must be of legal age and live at the current address
          </li>
          <li>Marksheets from High school</li>
          <li>A copy of the foreign national’s passport</li>
          <li>A valid VISA for a foreign national is required</li>
          <li>
            If you’re divorced, you’ll need a divorce decree certificate.
          </li>
          <li>
            Both parties should not be in an illicit connection to any extent
          </li>
          <li>Both parties should be mentally stable.</li>
          <li>6-6 Passport Size Photos of boy and girl.</li>
        </ul>
      </div>
      <div>
        <h3 className="font-bold text-center py-4">
          Eligibility For Court Marriage
        </h3>
        <ul className="list-disc px-20">
          <li>
            To begin with, neither party should have lived as husband or wife
            at the time of the marriage.
          </li>
          <li>The girl must be at least 18 years old</li>
          <li>The boy must be at least 21 years old</li>
          <li>
            Both parties should offer legitimate consent in the marriage
          </li>
        </ul>
      </div>
      <div>
        <h3 className="font-bold text-center py-4">
          Why Choose Us Laxmi Associates Law Firm?
        </h3>
        <p className="list-disc px-10">
          Expertise and Experience: Look for a law firm that has expertise and
          experience in the specific area of law that you require assistance
          with. A firm with knowledgeable and experienced attorneys can
          provide better guidance and representation.
        </p>
      </div>
    </div>
  </section>
  )
}

export default MarriageRegisteration
